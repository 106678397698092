import { Box, Grid } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BankInfoGrid } from 'components/BankInfoGrid/BankInfoGrid';
import { BudgetVsActual } from 'components/Charts/BudgetVsActual';
import { BudgetVsActualMonthly } from 'components/Charts/BudgetVsActualMonthly';
import { ExpenseForCounterparty } from 'components/Charts/ExpenseForCounterparty';
import { ExpensesForCompany } from 'components/Charts/ExpensesForCompany';
import { QuarterlyCompaniesExpenses } from 'components/Charts/QuarterlyCompaniesExpenses';
import { ReceivedInvoices } from 'components/Charts/ReceivedInvoices';
import { DocumentsInfoGrid } from 'components/DocumentsInfoGrid.tsx/DocumentsInfoGrid';

import { usePermissions } from 'context/PermissionsContext';

export const Dashboard = () => {
  const { companyId } = useParams();
  const { permissions } = usePermissions();

  const isAllCompanies = useMemo(() => !companyId, [companyId]);

  return (
    <Box
      sx={{
        width: '100%',
        p: { xs: 0, sm: 2 }
      }}
    >
      {isAllCompanies ? (
        <Grid container spacing={2}>
          {permissions.IBANS.read && (
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} md={6}>
                <BankInfoGrid
                  sx={{
                    height: { xs: 245, md: 245 },
                    width: '100%'
                  }}
                  isOnAllCompanies={isAllCompanies}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DocumentsInfoGrid
                  sx={{
                    height: { xs: 245, md: 245 },
                    width: '100%'
                  }}
                  isOnAllCompanies={isAllCompanies}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <QuarterlyCompaniesExpenses
              sx={{
                height: { xs: 300, sm: 350, md: 450 },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReceivedInvoices
              sx={{
                height: { xs: 300, sm: 350, md: 450 },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {permissions.IBANS.read && (
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} md={6}>
                <BankInfoGrid
                  sx={{
                    height: { xs: 245, md: 245 },
                    width: '100%'
                  }}
                  isOnAllCompanies={isAllCompanies}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DocumentsInfoGrid
                  sx={{
                    height: { xs: 245, md: 245 },
                    width: '100%'
                  }}
                  isOnAllCompanies={isAllCompanies}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <ExpensesForCompany
              sx={{
                height: { xs: 300, sm: 350, md: 450 },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ExpenseForCounterparty
              sx={{
                height: { xs: 300, sm: 350, md: 450 },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BudgetVsActual
              sx={{
                height: { xs: 300, sm: 350, md: 450 },
                width: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BudgetVsActualMonthly
              sx={{
                height: { xs: 300, sm: 350, md: 450 },
                width: '100%'
              }}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
