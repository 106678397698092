import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  Company,
  Invoice,
  InvoiceSection,
  DocumentType,
  PaymentChannel,
  PaymentStatus
} from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useFormHelpers } from 'hooks/useFormHelpers';

import {
  DOCUMENT_TYPES_TRANSLATION_KEYS,
  PAYMENT_CHANNELS_TRANSLATION_KEYS
} from 'utils/constants/invoices';
import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';

import { arrayFieldFormContainer } from 'styles/components/Common';
import { verificationFormItem } from 'styles/pages/InvoiceVerificationStyle';

import { ActionButtons } from './ActionButtons';

interface ReceiverFormProps {
  currentInvoice: Invoice;
  allCompanies: Company[];
  handleVerify: (
    values: Invoice & AdditionalInvoiceFields,
    section: InvoiceSectionKey
  ) => void;
  setIsReimbursement: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

export interface AdditionalInvoiceFields {
  isPrepaid?: boolean;
}

export const ReceiverForm = ({
  currentInvoice,
  allCompanies,
  handleVerify,
  setIsReimbursement
}: ReceiverFormProps) => {
  const { translate } = useTranslations();
  const { getOptionLabelReceiverName } = useFormHelpers();

  const { control, handleSubmit, getValues, watch, setValue, formState } =
    useForm<Invoice & AdditionalInvoiceFields>({
      defaultValues: {
        companyId: currentInvoice.companyId,
        documentType: currentInvoice.documentType
          ? currentInvoice.documentType
          : DocumentType.INVOICE,
        isPrepaid: currentInvoice.paymentStatus === PaymentStatus.PREPAID,
        paymentChannel:
          currentInvoice.paymentChannel || PaymentChannel.COMPANY_CARD,
        isReimbursement: currentInvoice.isReimbursement
      }
    });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleVerifySection = () => {
    handleVerify(
      {
        companyId: getValues('companyId'),
        documentType: getValues('documentType'),
        isPrepaid: getValues('isReimbursement')
          ? false
          : getValues('isPrepaid'),
        paymentChannel: getValues('paymentChannel'),
        isReimbursement: getValues('isReimbursement')
      },
      InvoiceSection.RECEIVER
    );
  };

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const watchedCompanyId = watch('companyId');
  const watchedIsPrePaid = watch('isPrepaid');
  const watchedIsReimbursement = watch('isReimbursement');

  const isReceiverWrong = useMemo(
    () =>
      currentInvoice.receiverRegistrationNumber !==
      allCompanies.find((company) => company.id === watchedCompanyId)
        ?.registrationNumber,
    [currentInvoice, watchedCompanyId, allCompanies]
  );

  useEffect(() => {
    setIsReimbursement(watchedIsReimbursement);
  }, [watchedIsReimbursement, setIsReimbursement]);

  useEffect(() => {
    if (watchedIsReimbursement) {
      setValue('isPrepaid', false);
    }
  }, [watchedIsReimbursement, setValue]);

  return (
    <Box sx={{ pt: 2.5 }}>
      <form onSubmit={handleSubmit(handleVerifySection)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2.2
          }}
        >
          <Box sx={{ display: 'flex', mb: 2.5 }}>
            <Controller
              name="isReimbursement"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  checked={field.value}
                  control={<Switch {...field} />}
                  label={translate('labels.reimbursement')}
                />
              )}
            />
          </Box>

          <ActionButtons isFormValid={isFormValid} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', lg: 'row' },
            gap: 2
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', lg: '50%' }
            }}
          >
            {allCompanies?.length > 0 && (
              <Box sx={{ ...arrayFieldFormContainer, flexDirection: 'column' }}>
                <Controller
                  name="companyId"
                  control={control}
                  render={({ field }) => (
                    <FormControl sx={verificationFormItem} fullWidth>
                      <InputLabel>{translate('labels.receiver')}</InputLabel>
                      <Select
                        {...field}
                        sx={{ textAlign: 'left' }}
                        label={translate('labels.receiver')}
                      >
                        {allCompanies.map((company: Company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {getOptionLabelReceiverName(company)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {isReceiverWrong && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: 30,
                      marginTop: 1,
                      gap: 0.5
                    }}
                  >
                    <WarningIcon
                      sx={{
                        fontSize: 22,
                        color: yellow[900],
                        display: currentInvoice.isReceiverVerified
                          ? 'none'
                          : 'block'
                      }}
                    />
                    <Typography fontSize={10}>
                      {translate('messages.receiverIsNotConfirmed')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: { sm: '100%', lg: '50%' }
            }}
          >
            <Controller
              name="documentType"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth sx={verificationFormItem}>
                  <InputLabel>{`${translate(
                    'labels.documentType'
                  )}*`}</InputLabel>
                  <Select
                    {...field}
                    sx={{ textAlign: 'left' }}
                    label={translate('labels.documentType')}
                  >
                    {Object.values(DocumentType)
                      .filter((docType) => docType !== DocumentType.NO_DOCUMENT)
                      .map((docType) => (
                        <MenuItem key={docType} value={docType}>
                          {translate(DOCUMENT_TYPES_TRANSLATION_KEYS[docType])}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
        </Box>

        {!watchedIsReimbursement && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: { xs: 0, lg: 1 }
            }}
          >
            <Controller
              name="isPrepaid"
              control={control}
              render={({ field }) => (
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      mr: { xs: 0, sm: 1 },
                      fontSize: { xs: '0.8rem', sm: '1rem' },
                      textAlign: 'left'
                    }}
                  >
                    {translate('labels.alreadyPaid')}
                  </Typography>
                  <Switch
                    size="medium"
                    defaultChecked={
                      currentInvoice.paymentStatus === PaymentStatus.PREPAID
                    }
                    {...field}
                  />
                </Box>
              )}
            />

            {watchedIsPrePaid && (
              <Controller
                name="paymentChannel"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <Typography sx={{ mb: 1, textAlign: 'left' }}>
                      {`${translate('labels.paymentChannel')}:`}
                    </Typography>
                    <RadioGroup
                      sx={{
                        flexDirection: { xs: 'column', sm: 'row', lg: 'row' }
                      }}
                      {...field}
                    >
                      {Object.values(PaymentChannel).map((channel) => (
                        <FormControlLabel
                          key={channel}
                          value={channel}
                          control={<Radio />}
                          label={translate(
                            PAYMENT_CHANNELS_TRANSLATION_KEYS[channel]
                          )}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
              />
            )}
          </Box>
        )}
      </form>
    </Box>
  );
};
