import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Divider
} from '@mui/material';
import React from 'react';

import { PromptBasedReport } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

interface ChatSideBarProps {
  chats: PromptBasedReport[];
  selectedChat?: PromptBasedReport;
  onSelectChat: (chat: PromptBasedReport) => void;
}

export const ChatSideBar: React.FC<ChatSideBarProps> = ({
  chats,
  selectedChat,
  onSelectChat
}) => {
  const { translate } = useTranslations();

  return (
    <Box
      sx={(theme) => ({
        borderRight: `1px solid ${theme.palette.divider}`,
        width: 300
      })}
    >
      <Box
        sx={{
          height: { md: 'calc(100vh - 65px)' },
          overflow: 'auto'
        }}
      >
        <List
          sx={{
            '.MuiDivider-root': {
              margin: 0
            }
          }}
        >
          {chats.map((chat) => {
            return (
              <React.Fragment key={chat.id}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => onSelectChat(chat)}
                    selected={chat.id === selectedChat?.id}
                  >
                    <ListItemAvatar
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        sx={{ height: 35, width: 35 }}
                        component="img"
                        src="/favicon.png"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        chat.userQuestion
                          ? chat.userQuestion
                          : translate('messages.noQuestionYet')
                      }
                      secondary={
                        chat.report
                          ? chat.report.replaceAll('#', '').replaceAll('*', '')
                          : translate('messages.noQuestionYet')
                      }
                      primaryTypographyProps={{
                        fontWeight: 'medium',
                        noWrap: true
                      }}
                      secondaryTypographyProps={{
                        noWrap: true
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};
