/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PromptBasedReportListResponse } from '../models/PromptBasedReportListResponse';
import type { PromptBasedReportRequest } from '../models/PromptBasedReportRequest';
import type { PromptBasedReportResponse } from '../models/PromptBasedReportResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PromptBasedReportingService {

    /**
     * This method returns the prompt based reporting data
     * @returns PromptBasedReportListResponse OK
     * @throws ApiError
     */
    public static getAllPromptBasedReports(): CancelablePromise<PromptBasedReportListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/prompt-based-reporting',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

    /**
     * This method creates a prompt based report
     * @param requestBody The prompt based report request object
     * @returns PromptBasedReportResponse OK
     * @throws ApiError
     */
    public static createPromptBasedReport(
        requestBody: PromptBasedReportRequest,
    ): CancelablePromise<PromptBasedReportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/prompt-based-reporting',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `BAD REQUEST`,
                401: `UNAUTHORIZED`,
                403: `FORBIDDEN`,
                500: `INTERNAL SERVER ERROR`,
            },
        });
    }

}
