import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH
} from 'styles/components/Common';

export const appStyle = {
  textAlign: 'center',
  width: '100%',
  height: '100vh'
};

export const displayWrapper = {
  display: 'grid',
  gridTemplateColumns: `${SIDEBAR_EXPANDED_WIDTH} 1fr`,
  height: '100%',
  width: '100%',
  transition: '0.5s'
};

export const expandedSidebarGrid = {
  gridTemplateColumns: `${SIDEBAR_EXPANDED_WIDTH} 1fr`
};

export const retractedSidebarGrid = {
  gridTemplateColumns: `${SIDEBAR_CLOSED_WIDTH} 1fr`
};

export const routesWrapper = {
  padding: '5px 20px 20px 20px',
  height: 'calc(100dvh - 64px)',
  margin: '0 auto',
  overflowX: 'hidden'
};

export const routesWrapperWithoutPadding = {
  padding: 0,
  height: 'calc(100dvh - 64px)',
  margin: '0 auto',
  overflowX: 'hidden'
};
