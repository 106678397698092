import { Box, FormControlLabel, Switch } from '@mui/material';
import { useState, useCallback, useEffect, useRef, Dispatch } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useCounterPartyController } from 'api/controllers/CounterPartyController';

import {
  CurrencyDropDown,
  Invoice,
  InvoiceExpenseType,
  InvoicePatch
} from 'openapi';

import { ActionButtons } from 'components/forms/InvoiceVerificationForms/ActionButtons';
import { CounterpartyFormSection } from 'components/forms/InvoiceVerificationForms/CreatedDocumentSections/CounterpartyFormSection';
import { InvoiceFormSection } from 'components/forms/InvoiceVerificationForms/CreatedDocumentSections/InvoiceFormSection';

import { useTranslations } from 'context/TranslationContext';

import {
  getCreatedDocumentDefaultValues,
  getCreatedDocumentFormValues
} from 'utils/helpers/invoiceHelpers';
import { CreatedDocument } from 'utils/interfaces/InvoiceProps';

import { verificationFormFieldsWrapper } from 'styles/pages/InvoiceVerificationStyle';

interface CreatedDocumentFormProps {
  currentInvoice: Invoice;
  handleVerify: (values: InvoicePatch) => Promise<void>;
  handleSave: (values: InvoicePatch) => void;
  handleGoToMiddleStep: () => void;
  setDocumentFormData: Dispatch<React.SetStateAction<Invoice | undefined>>;
  setCpIsNotFoundInDatabase: Dispatch<React.SetStateAction<boolean>>;
}

export const CreatedDocumentForm = ({
  currentInvoice,
  handleVerify,
  handleSave,
  handleGoToMiddleStep,
  setDocumentFormData,
  setCpIsNotFoundInDatabase
}: CreatedDocumentFormProps) => {
  const location = useLocation();
  const { translate } = useTranslations();
  const { getCurrencies, getExpenseTypes } = useAdditionalFieldsController();
  const { checkIfCounterpartyExists } = useCounterPartyController();

  const [currencies, setCurrencies] = useState<CurrencyDropDown[]>();
  const [expenseTypeOptions, setExpenseTypeOptions] =
    useState<InvoiceExpenseType[]>();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const isVerifiedRef = useRef<boolean>(isVerified);
  const selectedCounterpartyIdRef = useRef<number | null>(null);
  const isExistingRef = useRef<boolean>(true);

  const methods = useForm<CreatedDocument>({
    mode: 'onChange',
    defaultValues: getCreatedDocumentDefaultValues(currentInvoice)
  });

  const { formState, handleSubmit, getValues, watch, setValue } = methods;
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [selectedCounterPartyId, setSelectedCounterPartyId] = useState<
    number | null
  >(null);
  const [isExisting, setIsExisting] = useState<boolean>(true);

  const fetchCurrencies = useCallback(async () => {
    const currenciesResponse = await getCurrencies();
    setCurrencies(currenciesResponse);
  }, [getCurrencies]);

  const fetchExpenseTypes = useCallback(async () => {
    const expenseTypes = await getExpenseTypes(
      Number(currentInvoice.companyId)
    );
    setExpenseTypeOptions(expenseTypes);
  }, [getExpenseTypes, currentInvoice.companyId]);

  const handleNextStep = () => {
    handleGoToMiddleStep();
    setDocumentFormData(
      getCreatedDocumentFormValues(
        selectedCounterPartyId,
        currentInvoice,
        isExisting,
        getValues
      )
    );
  };

  useEffect(() => {
    fetchCurrencies();
    fetchExpenseTypes();
  }, [fetchCurrencies, fetchExpenseTypes]);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const validateForm = useCallback(() => {
    isVerifiedRef.current = true;
    setIsVerified(true);
    handleVerify(
      getCreatedDocumentFormValues(
        selectedCounterPartyId,
        currentInvoice,
        isExisting,
        getValues
      )
    );
  }, [
    handleVerify,
    getCreatedDocumentFormValues,
    selectedCounterPartyId,
    currentInvoice,
    isExisting,
    getValues
  ]);

  useEffect(() => {
    selectedCounterpartyIdRef.current = selectedCounterPartyId;
  }, [selectedCounterPartyId]);

  useEffect(() => {
    isExistingRef.current = isExisting;
  }, [isExisting]);

  useEffect(() => {
    const handleBeforeUnload = () =>
      handleSave(
        getCreatedDocumentFormValues(
          selectedCounterpartyIdRef.current,
          currentInvoice,
          isExistingRef.current,
          getValues
        )
      );

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [handleSave, getCreatedDocumentFormValues, currentInvoice]);

  useEffect(() => {
    return () => {
      if (isVerifiedRef.current) {
        return;
      }

      handleSave(
        getCreatedDocumentFormValues(
          selectedCounterpartyIdRef.current,
          currentInvoice,
          isExistingRef.current,
          getValues
        )
      );
    };
  }, [
    location.pathname,
    isVerified,
    handleSave,
    getCreatedDocumentFormValues,
    getValues
  ]);

  const watchedIsReimbursement = watch('isReimbursement');

  const checkIfCPExistsAndSave = useCallback(async () => {
    const response = await checkIfCounterpartyExists(
      Number(currentInvoice.companyId),
      getValues('counterpartyName') || '',
      getValues('counterpartyRegistrationNumber') || '',
      getValues('counterpartyVatNumber') || ''
    );
    setIsExisting(response.isCompanyCounterparty);
    handleSave(
      getCreatedDocumentFormValues(
        selectedCounterpartyIdRef.current,
        currentInvoice,
        response.isCompanyCounterparty,
        getValues
      )
    );
  }, [
    checkIfCounterpartyExists,
    handleSave,
    getCreatedDocumentFormValues,
    currentInvoice,
    getValues
  ]);

  useEffect(() => {
    checkIfCPExistsAndSave();
  }, [selectedCounterPartyId]);

  useEffect(() => {
    setValue('expenseType', String(currentInvoice.expenseType?.id || ''));
  }, [currentInvoice.expenseType, setValue]);

  return (
    <FormProvider {...methods}>
      <Box sx={{ pt: 2.5 }}>
        <form
          onSubmit={handleSubmit(
            isExisting || watchedIsReimbursement ? validateForm : handleNextStep
          )}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              sx={{
                ...verificationFormFieldsWrapper,
                height: '25px',
                mb: 3
              }}
            >
              <Controller
                name="isReimbursement"
                defaultValue={currentInvoice.isReimbursement}
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    control={
                      <Switch
                        {...field}
                        onChange={(event) => {
                          field.onChange(event);
                          setValue('counterpartyName', '');
                          setValue('counterpartyVatNumber', '');
                          setValue('counterpartyRegistrationNumber', '');
                        }}
                      />
                    }
                    label={translate('labels.reimbursement')}
                  />
                )}
              />
            </Box>
            <ActionButtons shouldVerify isFormValid={isFormValid} />
          </Box>

          {expenseTypeOptions && currencies && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: { xs: 0, lg: 4 },
                flexDirection: { xs: 'column', lg: 'row' }
              }}
            >
              <CounterpartyFormSection
                setSelectedCounterPartyId={setSelectedCounterPartyId}
                setIsExisting={setIsExisting}
                setCpIsNotFoundInDatabase={setCpIsNotFoundInDatabase}
                isReimbursement={watchedIsReimbursement}
              />
              <InvoiceFormSection
                expenseTypeOptions={expenseTypeOptions}
                currencies={currencies}
                currentInvoice={currentInvoice}
              />
            </Box>
          )}
        </form>
      </Box>
    </FormProvider>
  );
};
