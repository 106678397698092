import { Box } from '@mui/material';
import React from 'react';

export const TypingIndicator: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        component="span"
        className="typing-dot"
        style={{ animationDelay: '0s' }}
      />
      <Box
        component="span"
        className="typing-dot"
        style={{ animationDelay: '0.2s' }}
      />
      <Box
        component="span"
        className="typing-dot"
        style={{ animationDelay: '0.4s' }}
      />
    </Box>
  );
};
