import { useCallback } from 'react';

import { PromptBasedReportingService, PromptBasedReportRequest } from 'openapi';

import { useInterceptor } from 'hooks/useInterceptor';

export const usePromptBasedReportingController = () => {
  const { interceptRequest } = useInterceptor();
  const { getAllPromptBasedReports, createPromptBasedReport } =
    PromptBasedReportingService;

  const getPromptBasedReports = useCallback(
    () => interceptRequest(getAllPromptBasedReports, {}),
    [getAllPromptBasedReports]
  );

  const createPromptBaseReport = useCallback(
    (requestBody: PromptBasedReportRequest) =>
      interceptRequest(createPromptBasedReport, {}, requestBody),
    [createPromptBasedReport]
  );

  return {
    getPromptBasedReports,
    createPromptBaseReport
  };
};
