import {
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Paper
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { BsCircleFill } from 'react-icons/bs';
import { FiSend, FiMenu } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { usePromptBasedReportingController } from 'api/controllers/PromptBasedReportingController';

import { PromptBasedReport } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { TypingIndicator } from './TypingIndicator';

interface ChatAreaProps {
  isMobile: boolean;
  onOpenSidebar: () => void;
  createNewChat: (question: string) => PromptBasedReport;
  updateChat: (newChat: PromptBasedReport) => void;
  selectedChat?: PromptBasedReport;
}

export const ChatArea: React.FC<ChatAreaProps> = ({
  isMobile,
  onOpenSidebar,
  createNewChat,
  updateChat,
  selectedChat
}) => {
  const { createPromptBaseReport } = usePromptBasedReportingController();
  const { translate } = useTranslations();

  const [question, setQuestion] = useState('');
  const [isAwaitingReport, setIsAwaitingReport] = useState(false);

  useEffect(() => {
    setQuestion('');
  }, [selectedChat]);

  const handleAskQuestion = async () => {
    if (!question.trim()) return;

    setIsAwaitingReport(true);

    const newQuestion = question.trim();
    const newChat = createNewChat(newQuestion);
    try {
      const result = await createPromptBaseReport({
        userQuestion: newQuestion
      });

      const finalAnswer = result?.report || '';
      updateChat({ ...newChat, report: finalAnswer });
      setIsAwaitingReport(false);
    } catch (err) {
      setIsAwaitingReport(false);
    } finally {
      setQuestion('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleAskQuestion();
    }
  };

  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`
        })}
      >
        <TextField
          fullWidth
          multiline
          maxRows={4}
          minRows={2}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={translate('messages.askAQuestion')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleAskQuestion} color="primary">
                  <FiSend />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box
        sx={(theme) => ({
          padding: theme.spacing(2),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2)
        })}
      >
        {isMobile && (
          <IconButton onClick={onOpenSidebar}>
            <FiMenu />
          </IconButton>
        )}
        <Box
          sx={{ height: 35, width: 35 }}
          component="img"
          src="/favicon.png"
        />
        <Box>
          <Typography variant="h6">
            {selectedChat ? `ExpensePilot` : translate('messages.askAQuestion')}
          </Typography>
          <Box display="flex" alignItems="center" gap={0.5}>
            <BsCircleFill size={8} color="#4caf50" />
            <Typography variant="body2" color="textSecondary">
              {translate('labels.online')}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          padding: '20px'
        }}
      >
        {selectedChat?.userQuestion && (
          <Paper
            sx={(theme) => ({
              padding: theme.spacing(1.5),
              marginBottom: theme.spacing(2),
              maxWidth: '70%',
              width: 'fit-content',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              marginLeft: 'auto',
              borderRadius: '12px',
              wordWrap: 'break-word',
              overflowWrap: 'anywhere',
              textAlign: 'left'
            })}
            elevation={0}
          >
            <Typography>{selectedChat.userQuestion}</Typography>
          </Paper>
        )}

        {selectedChat?.report && (
          <Paper
            sx={(theme) => ({
              padding: theme.spacing(1.5),
              marginBottom: theme.spacing(2),
              maxWidth: '70%',
              width: 'fit-content',
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              marginLeft: theme.spacing(1),
              borderRadius: '12px',
              wordWrap: 'break-word',
              overflowWrap: 'anywhere',
              textAlign: 'start',
              '& ul': { paddingLeft: '20px', listStyle: 'disc' },
              '& ol': { paddingLeft: '20px', listStyle: 'decimal' }
            })}
            elevation={0}
          >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {selectedChat?.report?.replace(/\\n/g, '\n')}
            </ReactMarkdown>
          </Paper>
        )}

        {isAwaitingReport && (
          <Paper
            sx={(theme) => ({
              padding: theme.spacing(1.5),
              marginBottom: theme.spacing(2),
              maxWidth: '70%',
              width: 'fit-content',
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              marginLeft: theme.spacing(1),
              borderRadius: '12px',
              wordWrap: 'break-word',
              overflowWrap: 'anywhere',
              textAlign: 'left'
            })}
            elevation={0}
          >
            <TypingIndicator />
          </Paper>
        )}
      </Box>
    </Box>
  );
};
