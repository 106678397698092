import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Grid, Box, TextField, Button, Tooltip, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from 'react-hook-form';

import { User, CounterpartyApprovalPatch, CounterpartyApproval } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { AMOUNT_MAX, AMOUNT_MIN } from 'utils/constants/constants';
import { validateAmount } from 'utils/helpers/approvalsHelpers';
import { mapCounterpartyApprovalValues } from 'utils/mappers/counterParty';

import { submitButton } from 'styles/components/Common';
import { verificationFormFieldsWrapper } from 'styles/pages/InvoiceVerificationStyle';

interface UpdateCounterpartyApprovalProps {
  onSubmit: (values: CounterpartyApprovalPatch) => void;
  initialValues: CounterpartyApproval;
  approvers: User[];
}
export const UpdateCounterpartyApproval = ({
  onSubmit,
  initialValues,
  approvers
}: UpdateCounterpartyApprovalProps) => {
  const { translate } = useTranslations();

  const { handleSubmit, control, formState } = useForm<
    CounterpartyApprovalPatch & { name: string }
  >({
    defaultValues: mapCounterpartyApprovalValues(initialValues),
    mode: 'onChange'
  });

  return (
    <Grid container sx={{ ...verificationFormFieldsWrapper, width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid item mb={3} height={60}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            disabled
            render={({ field }) => (
              <TextField
                {...field}
                label={`${translate('labels.name')}*`}
                name="name"
                placeholder={translate('labels.name')}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item mb={3} height={60}>
          <Controller
            name="approvalThreshold"
            control={control}
            rules={{
              required: true,
              validate: validateAmount
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={`${translate('labels.amount')}*`}
                name="value"
                placeholder={`${translate('labels.amount')}*`}
                error={!!error}
                helperText={
                  error &&
                  translate('errors.amountOutOfRange', {
                    min: AMOUNT_MIN.toString(),
                    max: AMOUNT_MAX.toString()
                  })
                }
                fullWidth
                inputProps={{
                  pattern: '[0-9]*' // HTML5: allows only digits
                }}
              />
            )}
          />
        </Grid>

        <Grid item mb={3}>
          <Controller
            name="approverIds"
            control={control}
            render={({ field, fieldState }) => (
              <Autocomplete
                defaultValue={approvers.filter((approver) =>
                  field!.value!.includes(approver.id)
                )}
                multiple
                options={approvers}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name!}
                onChange={(e, value) => {
                  field.onChange(value.map((v) => v.id));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value
                    .slice(0, 2)
                    .map((option, index) => (
                      <Chip
                        sx={{ width: '35%' }}
                        label={option.name}
                        {...getTagProps({ index })}
                        disabled={index > 1}
                        size="small"
                      />
                    ))
                    .concat(
                      value.length > 2
                        ? [
                            <Chip
                              size="small"
                              key="more"
                              label={`+${value.length - 2} ${translate(
                                'labels.more'
                              )}`}
                            />
                          ]
                        : []
                    )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${translate('labels.approvers')}*`}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error && translate('errors.approver')
                    }
                  />
                )}
              />
            )}
          />
        </Grid>

        <Tooltip
          title={!formState.isValid ? translate('errors.invalidData') : ''}
        >
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              type="submit"
              sx={submitButton}
              disabled={!formState.isValid}
            >
              {translate('buttons.save')}
            </Button>
          </Box>
        </Tooltip>
      </form>
    </Grid>
  );
};
