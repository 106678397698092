import {
  useTheme,
  useMediaQuery,
  Drawer,
  Box,
  Stack,
  CircularProgress
} from '@mui/material';
import { useEffect, useState } from 'react';

import { usePromptBasedReportingController } from 'api/controllers/PromptBasedReportingController';

import { PromptBasedReport } from 'openapi';

import { DEFAULT_PROMPT_BASED_REPORT } from 'utils/constants/promptBasedReporting';

import { ChatArea } from './ChatArea';
import { ChatSideBar } from './ChatSidebar';

export const PromptBasedReporting = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { getPromptBasedReports } = usePromptBasedReportingController();

  const [chats, setChats] = useState<PromptBasedReport[]>([]);
  const [selectedChat, setSelectedChat] = useState<PromptBasedReport>();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(true);

  const fetchChats = async () => {
    try {
      const response = await getPromptBasedReports();
      setIsLoadingChats(false);
      if (response.length === 0) {
        response.push(DEFAULT_PROMPT_BASED_REPORT);
      }
      response.reverse();
      setChats(response);
      if (!selectedChat && response.length > 0) {
        setSelectedChat(response[0]);
      }
    } catch (error) {
      setChats([DEFAULT_PROMPT_BASED_REPORT]);
      setIsLoadingChats(false);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  const handleUpdateChat = (newChat: PromptBasedReport) => {
    setChats((prev) =>
      prev.map((chat) => (chat.id === newChat.id ? newChat : chat))
    );
    setSelectedChat(newChat);
  };

  const handleCreateNewChat = (question: string) => {
    if (chats.length === 1 && !chats[0].userQuestion) {
      const newChat = { id: 1, userQuestion: question, report: '' };
      handleUpdateChat(newChat);
      return newChat;
    }
    const newChat: PromptBasedReport = {
      id: chats.length + 1,
      userQuestion: question,
      report: ''
    };
    setChats((prev) => [newChat, ...prev]);
    setSelectedChat(newChat);
    return newChat;
  };

  const handleSelectChat = (chat: PromptBasedReport) => {
    setSelectedChat(chat);
    if (isMobile) setMobileOpen(false);
  };

  return isLoadingChats ? (
    <Stack alignItems="center" justifyContent="center" height="80vh">
      <CircularProgress />
    </Stack>
  ) : (
    <Box
      sx={(localTheme) => ({
        display: 'flex',
        height: '100%',
        backgroundColor: localTheme.palette.background.default
      })}
    >
      {isMobile ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{ keepMounted: true }}
        >
          <ChatSideBar
            chats={chats}
            selectedChat={selectedChat}
            onSelectChat={handleSelectChat}
          />
        </Drawer>
      ) : (
        <ChatSideBar
          chats={chats}
          selectedChat={selectedChat}
          onSelectChat={handleSelectChat}
        />
      )}

      <ChatArea
        isMobile={isMobile}
        onOpenSidebar={() => setMobileOpen(true)}
        selectedChat={selectedChat}
        createNewChat={handleCreateNewChat}
        updateChat={handleUpdateChat}
      />
    </Box>
  );
};
